<script>
import { ArrowUpIcon, MessageCircleIcon } from "vue-feather-icons";
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import moment from "moment";
import JobSeekerService from "@/MainServices/JobSeekerService.js";
import Swal from "sweetalert2";

/**
 * Page-job-apply component
 */
export default {
  data() {
    return {
      city: [],
      cityOptions: [],
      mediaUrl: MEDIA_URL,
      cities: this.$route.params.cities
        .split("-")
        .map((a) => a.charAt(0).toUpperCase() + a.substr(1)),
      title: this.$route.params.title.replaceAll("-", " "),
      job: {},
      cvs: [],
      selectedCV: "null",
      answers: [],
      expected_salary: "",
      agree: false,
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    MessageCircleIcon,
  },
  mounted() {
    const id_ = window.location.pathname.split("/").pop();
    if (localStorage.getItem("user") == null) {
      this.$router.push("/login");
      return;
    }
    JobSeekerService.getMyCvs()
      .then((res) => {
        this.cvs = res.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
    axios
      .post(`${API_URL}/get-job`, {
        id: id_,
        cities: this.cities,
        title: this.title,
      })
      .then((response) => {
        this.job = response.data;
        this.cityOptions = response.data.location_city;
      });
  },
  methods: {
    apply() {
      if (this.selectedCV === "null") {
        Swal.fire({
          title: "",
          text: "Please, select your cv!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.city.length == 0) {
        Swal.fire({
          title: "",
          text: "Please, select desired job locations!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.expected_salary == "") {
        Swal.fire({
          title: "",
          text: "Please, select Expected salary!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.agree == false) {
        Swal.fire({
          title: "",
          text: "Please, accept term and condition!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      var payload = {
        job_id: this.job.id,
        recruiter_id: this.job.recruiter.user_id,
        job_seeker_id:
          localStorage.getItem("user") !== null
            ? JSON.parse(localStorage.getItem("user")).id
            : null,
        cv_id: this.selectedCV,
        city: this.city,
        expected_salary: this.expected_salary,
        answers: this.answers,
      };
      JobSeekerService.applyForJob(payload).then((res) => {
        Swal.fire({
          title: "",
          text: res.data,
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        }).then(function () {
          window.location = "/jobs-in-pakistan";
        });
      });
    },
    changeQuestionSelect(n1, n2, n3, n4) {
      var temp = [];
      this.answers.forEach((e) => {
        if (e.id !== n2) {
          temp.push(e);
        }
      });
      temp.push({ id: n2, question: n4, answer: JSON.stringify(n1), type: n3 });
      this.answers = temp;
    },
    changeQuestionRadio(n1, n2, n3, n4) {
      var temp = [];
      this.answers.forEach((e) => {
        if (e.id !== n2) {
          temp.push(e);
        }
      });
      temp.push({ id: n2, question: n4, answer: n1.target.value, type: n3 });
      this.answers = temp;
    },
    changeQuestionTextarea(n1, n2, n3, n4) {
      var temp = [];
      this.answers.forEach((e) => {
        if (e.id !== n2) {
          temp.push(e);
        }
      });
      temp.push({ id: n2, question: n4, answer: n1.target.value, type: n3 });
      this.answers = temp;
    },
    agree_fun() {
      this.agree = !this.agree;
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section>
      <!-- <h4 class="title">Submit Job Application</h4> -->
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Job apply form Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-7">
            <div class="card custom-form border-0">
              <div class="card-body p-0">
                <div class="pl-4 pr-4 pt-4 pb-4 mb-0 job_listing_header">
                  <h3
                    class="title mb-0 simple-heading"
                    style="
                      color: #00bdf6;
                      padding: 0 !important;
                      font-size: 20px;
                    "
                  >
                    Submit Job Application for:
                    <span class="simple-heading" style="font-size: 20px"
                      >{{ job.job_title }} at
                      {{ job.recruiter.company_name }}
                      </span
                    >
                  </h3>
                  <!-- <span class=""> </span> -->
                </div>
                <form class="rounded shadow p-4 apply_page_ch">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group position-relative">
                        <label
                          >Your CV :<span class="text-danger">*</span></label
                        >
                        <select
                          class="form-control custom-select rounded-0"
                          id="Sortbylist-Shop"
                          v-model="selectedCV"
                        >
                          <option value="null">choose cv</option>
                          <template v-for="(_item, _index) in cvs">
                            <option :value="_item.id" :key="_index">
                              {{ _item.cv_title }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-4">
                      <div class="form-group position-relative">
                        <label
                          >Desired job locations<span class="text-danger"
                            >*</span
                          ></label
                        >
                        <v-select
                          v-model="city"
                          :items="cityOptions"
                          attach
                          chips
                          multiple
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group position-relative">
                        <label
                          >Expected salary :<span class="text-danger"
                            >*</span
                          ></label
                        >
                        <select
                          class="form-control custom-select rounded-0"
                          id="Sortbylist-Shop"
                          v-model="expected_salary"
                        >
                          <!-- <option value="null">Salary Range</option> -->
                          <option v-bind:selected="true" value="null">
                            Salary Range
                          </option>
                          <option value="20000">&gt; Upto 20,000</option>
                          <option value="20001-40000">20,001 - 40,000</option>
                          <option value="40001-60000">40,001 - 60,000</option>
                          <option value="60001-80000">60,001 - 80,000</option>
                          <option value="80001-100000">80,001 - 100,000</option>
                          <option value="100001-125000">
                            100,001 - 125,000
                          </option>
                          <option value="125001-150000">
                            125,001 - 150,000
                          </option>
                          <option value="150001-175000">
                            150,001 - 175,000
                          </option>
                          <option value="175001-225000">
                            175,001 - 225,000
                          </option>
                          <option value="225001-275000">
                            225,001 - 275,000
                          </option>
                          <option value="275001-300000">
                            275,001 - 300,000
                          </option>
                          <option value="300000-350000">
                            300,000 - 350,000
                          </option>
                          <option value="More than 350000">350,000 +</option>
                        </select>
                      </div>
                    </div>

                    <!--end col-->
                    <template v-for="(item, index) in job.questions">
                      <div class="col-md-4" :key="index">
                        <div class="form-group">
                          <label
                            >{{ item.question }} :
                            <span class="text-danger" v-if="item.mandatory == 1"
                              >*</span
                            ></label
                          >
                          <!-- <select
                            v-if="item.type == 'multi'"
                            class="form-control custom-select rounded-0"
                            id="Sortbylist-Shop"
                            v-model="q1"
                            @change="
                              changeQuestionSelect(
                                $event,
                                item.id,
                                'multi',
                                item.question
                              )
                            "
                          >
                            <template
                              v-for="(_item, _index) in JSON.parse(item.answer)"
                            >
                              <option :value="_item" :key="_index">
                                More then {{ _item }} years
                              </option>
                            </template>
                          </select> -->

                          <v-select
                            :items="JSON.parse(item.answer)"
                            attach
                            chips
                            class="dropdown_ch_years"
                            label="choose"
                            v-model="q1"
                            @change="
                              changeQuestionSelect(
                                $event,
                                item.id,
                                'multi',
                                item.question
                              )
                            "
                          ></v-select>

                          <div
                            class="custom-control custom-radio"
                            v-if="item.type == 'radio'"
                          >
                            <div
                              class="
                                custom-control
                                custom-radio
                                custom-control-inline
                              "
                            >
                              <div class="form-group mb-0">
                                <input
                                  type="radio"
                                  :id="item.question + 0"
                                  :name="item.question"
                                  class="custom-control-input"
                                  value="Yes"
                                  v-on:click="
                                    changeQuestionRadio(
                                      $event,
                                      item.id,
                                      'radio',
                                      item.question
                                    )
                                  "
                                />
                                <label
                                  class="custom-control-label"
                                  :for="item.question + 0"
                                  >Yes</label
                                >
                              </div>
                            </div>

                            <div
                              class="
                                custom-control
                                custom-radio
                                custom-control-inline
                              "
                            >
                              <div class="form-group mb-0">
                                <input
                                  type="radio"
                                  :id="item.question + 1"
                                  :name="item.question"
                                  class="custom-control-input"
                                  value="No"
                                  v-on:click="
                                    changeQuestionRadio(
                                      $event,
                                      item.id,
                                      'radio',
                                      item.question
                                    )
                                  "
                                />
                                <label
                                  class="custom-control-label"
                                  :for="item.question + 1"
                                  >No
                                </label>
                              </div>
                            </div>
                          </div>

                          <template v-if="item.type == 'textarea'">
                            <message-circle-icon
                              class="fea icon-sm icons"
                            ></message-circle-icon>
                            <textarea
                              name="comments"
                              id="comments"
                              rows="4"
                              class="form-control pl-5"
                              placeholder="Describe the job :"
                              v-model="q3"
                              @change="
                                changeQuestionTextarea(
                                  $event,
                                  item.id,
                                  'textarea',
                                  item.question
                                )
                              "
                            ></textarea>
                          </template>
                        </div>
                      </div>
                    </template>

                    <!--end col-->
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                            @click="agree_fun()"
                          />
                          <label class="custom-control-label" for="customCheck1"
                            >I have read and Accept the
                            <a href="#" class="text-primary"
                              >Terms And Condition</a
                            ></label
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                  <div class="row">
                    <div class="col-2">
                      <router-link class="" to="/jobs-in-pakistan">
                        <button class="submitBnt btn btn-danger" type="button">
                          Cancel
                        </button>
                      </router-link>
                    </div>
                    <div class="col-lg-3">
                      <button
                        class="submitBnt btn btn-primary"
                        v-on:click="apply"
                        type="button"
                      >
                        Submit application
                      </button>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
                <!--end form-->
              </div>
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Job apply form End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
