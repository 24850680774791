import ApiInstance from './ApiInstance.js'

export default {
  
  getMyCvs() {
    return ApiInstance({ requiresAuth: true }).get('/job-seeker-get-cvs');
  },
  deleteMyCv(id) {
    return ApiInstance({ requiresAuth: true }).delete(`/job-seeker-delete-cv/${id}`);
  },
  getMyVideos(){
    return ApiInstance({ requiresAuth: true }).get(`/job-seeker-intro-videos`);
  },
  deleteMyVideo(id) {
    return ApiInstance({ requiresAuth: true }).delete(`/job-seeker-delete-intro-video/${id}`);
  },
  getMyAudios(){
    return ApiInstance({ requiresAuth: true }).get(`/job-seeker-intro-audio`);
  },
  deleteMyAudio(id) {
    return ApiInstance({ requiresAuth: true }).delete(`/job-seeker-delete-intro-audio/${id}`);
  },
  applyForJob(data){
    return ApiInstance({ requiresAuth: true }).post(`/job-seeker-apply`, data);
  },
  getJobApplications() {
    return ApiInstance({ requiresAuth: true }).get('/my-job-applications');
  },
  withdrawJobApplication(id) {
    return ApiInstance({ requiresAuth: true }).get(`/job-seeker-withdraw-request/${id}`);
  },
  getConversations(id)
  {
    return ApiInstance({ requiresAuth: true }).get(`/get-conversations/${id}`);
  }
}
